import { Box, Button, Text, TextField } from '@aftership/design-system';
import { withPreviewSection } from '@aftership/preview-kit/client';

import ClickWrap from '@/features/order-lookup/components/Clickwrap';
import Policy from '@/features/order-lookup/components/Policy';

import { PreviewSectionName } from './PreviewSectionName';

export const OrderNumberTextField = withPreviewSection(
  TextField,
  PreviewSectionName.orderNumberPrompt,
);

export const EmailTextField = withPreviewSection(TextField, PreviewSectionName.emailPrompt);

export const OrderLookupFormTitleText = withPreviewSection(
  Text,
  PreviewSectionName.MAIN_FORM_TITLE_TEXT,
);

export const OrderLookupSubmitButton = withPreviewSection(
  Button,
  PreviewSectionName.MAIN_FIND_YOUR_ORDER_BUTTON,
);

export const MainGiftReturnButton = withPreviewSection(
  Button,
  PreviewSectionName.MAIN_GIFT_RETURN_BUTTON,
);

export const PolicyWithPreviewSection = withPreviewSection(
  Policy,
  PreviewSectionName.MAIN_POLICY_SUMMARY_TEXT,
);

export const ClickWrapWithPreviewSection = withPreviewSection(
  ClickWrap,
  PreviewSectionName.MAIN_CLICKWRAP,
);

export const HomeCarouselWrapperWithPreviewSection = withPreviewSection(
  Box,
  PreviewSectionName.MAIN_MARKETING_ASSETS,
);

export const ReturnItemTitleText = withPreviewSection(
  Text,
  PreviewSectionName.RETURNS_ITEM_PRIMARY_TITLE,
);

export const RequestReturnsCardTitleText = withPreviewSection(
  Text,
  PreviewSectionName.REQUEST_RETURNS_PAGE_TITLE,
);

export const NonReturnableTitleText = withPreviewSection(
  Text,
  PreviewSectionName.NON_RETURNABLE_SECTION,
);

export const ResolutionCardTitleText = withPreviewSection(
  Text,
  PreviewSectionName.RESOLUTION_PAGE_TITLE,
);

export const ReturnMethodPageTitleText = withPreviewSection(
  Text,
  PreviewSectionName.SHIPPING_PAGE_TITLE,
);

export const ReturnMethodItemBox = withPreviewSection(Box, PreviewSectionName.SHIPPING_ITEM);

export const RequestReviewCardTitleText = withPreviewSection(
  Text,
  PreviewSectionName.REQUEST_REVIEW_PAGE_TITLE,
);

export const ReturnItemsSectionTitleText = withPreviewSection(
  Text,
  PreviewSectionName.RETURN_ITEMS_SECTION_TITLE,
);

export const ExchangeItemsSectionTitleText = withPreviewSection(
  Text,
  PreviewSectionName.EXCHANGE_ITEMS_SECTION_TITLE,
);

export const ReturnMethodSectionTitleText = withPreviewSection(
  Text,
  PreviewSectionName.SHIPPING_SECTION_TITLE,
);
